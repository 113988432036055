<template>
  <div class="calendy">
    <div class="calendy__widget" id="calendy_widget"></div>
  </div>
</template>


<script>


export default {
  props:{
    calendly_url:String
  },
  mounted() {

    console.log("calendly_url", this.calendly_url)

    window.Calendly.initInlineWidget({
      url: this.calendly_url,
      parentElement: document.getElementById('calendy_widget'),
      prefill: {},
      utm: {}
    });
  }
}

</script>
