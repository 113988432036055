import SupaBaseModel from "./SupaBaseModel";
import {SupabaseClient} from "./SuperbaseClient";

export default class ContentPage extends SupaBaseModel {
    static data_name = 'content_pages';

    static newDefault(){
        let instance = new this();
        instance.active = true;
        instance.title = "";
        instance.path  = "";
        instance.use_advanced_editor = false;
        return instance;
    }

    get created_at_format(){
      return this.formatDate(this.created_at);
    }

    urlForImage(image){
        return "https://vosqqgykjafrywdcpsfz.supabase.co/storage/v1/object/public/franchise_opportunity_images/" + image.path;
    }

    get main_image_url(){
        return this.images && this.images.length > 0  ? this.urlForImage(this.images[0]) : "";
    }

    get image_urls(){
        return this.images.map( image => this.urlForImage(image));
    }

    static async getByPath(path){

        let { data, error }  = await SupabaseClient.rpc('page_with_path',{ page_path: path });
        //console.log("data",data)
        //console.log("error", error)

        if(error) throw error;

        if(data.length > 0)
            return this.instanceFromData(data[0]);

        return null;
    }
}