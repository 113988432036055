import SupaBaseModel from "./SupaBaseModel";
import {SupabaseClient} from "./SuperbaseClient";

export default class FranchiseOpportunity extends SupaBaseModel {
    static data_name = 'franchise_opportunities';

    static newDefault(){
        let instance = new this();
        instance.active = true;
        instance.title = "";
        instance.description = "";
        instance.details = "";
        instance.state_id = 0;
        instance.suburb = "";
        instance.from_price = 0;
        instance.to_price = 0
        instance.is_new_opportunity = false;
        instance.is_salon = true;
        instance.latitude = -33.884975;
        instance.longitude = 151.215396;
        instance.features = "";
        instance.quote = ""
        return instance;
    }

    static async getWithDetails(id){
        const { data, error } = await SupabaseClient.from('franchise_opportunity_details')
            .select()
            .eq('id', id)
            .limit(1);

        if(error)
            throw error;


        return data.length < 1 ? null : this.instanceFromData(data[0]);
    }

    static async allActiveWithDetails(limit = 200, excluded_opportunity_id = -1){
        const { data, error } = await SupabaseClient.from('franchise_opportunity_details')
            .select()
            .eq("active", true)
            .neq('id', excluded_opportunity_id)
            .order('id', { ascending: false })
            .limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    static async allWithDetails(limit = 200, excluded_opportunity_id = -1){
        const { data, error } = await SupabaseClient.from('franchise_opportunity_details')
            .select()
            .neq('id', excluded_opportunity_id)
            .order('id', { ascending: false })
            .limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    get created_at_format(){
      return this.formatDate(this.created_at);
    }

    get main_image_url(){
        return this.images && this.images.length > 0  ? this.images[0] : "";
    }


}