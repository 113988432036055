import { createApp } from "vue";


import App from './app.vue'
import router from './router/router'
import store from './store/store'
import './assets/scss/app.scss'

router.$store = store;

let app = createApp(App)

app.use(store);
app.use(router);


Math.clamp = function(value, min, max) {
  return Math.min(Math.max(value, min), max);
};

const mixins = require.context('./mixins/', true, /\.js$/);

mixins.keys().forEach(key => {
  app.mixin(mixins(key).default);
});



const directives = require.context('./directives/', true, /\.js$/);

directives.keys().forEach(key => {
  const directive = directives(key).default;
  const name = key.replace("./", "").replace(".js", "");
  //console.log('directive', name); //v-accordion
  app.directive(name, directive);
});


const vueComponents = require.context('./components/', true, /\.(vue|js)$/);

vueComponents.keys().forEach(key => {
  const component = vueComponents(key).default;
  const name = component.name
    ? component.name
    : key.replace(/\.(\/|vue|js)/g, '').replace(/(\/|-|_|\s)\w/g, (match) => match.slice(1).toUpperCase());

 // console.log('component', name);
  app.component(name, component);
});


app.mount('#app');

