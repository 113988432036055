
import { SupabaseClient } from './SuperbaseClient'


export default class SupaBaseModel {
    static data_name; // Firestore table name must be defined in subclass

    // Queries

    static async get(id){
       let { data, error }  = await this.client.select().match({ id: id});

       if(error) throw error;

       if(data.length > 0)
           return this.instanceFromData(data[0]);

       return null;
    }

    static async find(params){
        let { data, error }  = await this.client.select().match(params);

        if(error) throw error;

        if(data.length > 0)
            return this.instanceFromData(data[0]);

        return null;
    }

    static async all(limit = 1000) {
        const { data, error } = await this.client.select().limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    static async allOrdered(column, ascending = true, limit = 1000) {
        const { data, error } = await this.client.select().order(column, { ascending: ascending }).limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    static async where(column, value) {
        const { data, error } = await this.client.select().eq(column, value);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    static async whereOrderedBy(column, value, ordered_by, ascending = true, limit = 1000) {
        const { data, error } = await this.client.select().eq(column, value).order(ordered_by, { ascending: ascending }).limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }


    static async count(){
        const { count, error } = await this.client.select('*', { count: 'exact', head: true })

        if(error)
            throw error;

        return count;
    }


    // Static Utils

    static get supabase(){
        return SupabaseClient;
    }

    static get client(){
        return SupabaseClient.from(this.data_name);
    }

    static instanceItemsFromData(data_array){
        return data_array.map(data => this.instanceFromData(data));
    }

    static instanceFromData(data){
        let instance = new this();
        Object.assign(instance, data);
        return instance;
    }

    async create(){
        const { data, error } = await this.constructor.client.insert(this.toData());
        if(error) throw error;
    }

    async createAndUpdateObject(){
        const { data, error } = await this.constructor.client.insert(this.toData()).select();
        if(error) throw error;
        if (data.length > 0){
            Object.assign(this, data[0]);
        }
    }

    async save(){
        const { error } = await this.constructor.client.upsert(this.toData());
        if(error) throw error;
    }

    async delete(){
        const { error } = await this.constructor.client.delete().eq('id', this.id)
        if(error) throw error;
    }

    toData() {
        return Object.assign({}, this);
    }


    formatDate(date_string){
        return (new Date(date_string)).toLocaleString('en-GB', {
            year: 'numeric', month: 'short', day: 'numeric',
            timeZone: 'Australia/Sydney' })
    }

    formatDateWithTime(date_string){
        return (new Date(date_string)).toLocaleString('en-GB', {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: '2-digit', minute:'2-digit', hour12: true,
            timeZone: 'Australia/Sydney' })
    }
}
