<template>
  <div class="admin-pagination">
    <template  v-for="(pagination_item) in pagination_items">
      <a class="admin-pagination__item"
         :class="{ 'admin-pagination__item--current': pagination_item.index == current_index }"
         v-if="canShow(pagination_item)"
         @click="paginationItem__clicked(pagination_item)">
        {{ pagination_item.text }}
      </a>
      <div class="admin-pagination__spacer" v-else>...</div>
    </template>

  </div>
</template>

<script>

export default {
  props: {
    current_index: { default: 0 },
    page_size: { default: 0 },
    total_count: { default: 0 }
  },
  data() {
    return {

    }
  },
  mounted(){

  },
  computed:{
    pagination_items(){
      return [{ direction: -1, text: "Prev" },
        ...Array.from({ length: Math.ceil(this.total_count / this.page_size) },
            (_, index) => ({ index: index, text: index + 1 })),
        { direction: 1, text: "Next" }];
    },
    max_pagination_index(){
      return Math.max(...this.pagination_items.filter(u => u.index > 0).map(u => u.index));
    }
  },
  methods:{
    canShow(pagination_item){
      return (pagination_item.index >= this.current_index - 3 && pagination_item.index <= this.current_index + 3) ||
              pagination_item.index == undefined ||
             pagination_item.index < 4 ||
              pagination_item.index > this.max_pagination_index - 4;
    },
    paginationItem__clicked(pagination_item){
      let new_index = this.current_index;
      if(pagination_item.index != undefined){
        new_index = pagination_item.index;
      } else{
        new_index = Math.clamp(this.current_index +  pagination_item.direction, 0, this.pagination_items.length - 3);
      }
      this.$emit("current_index_changed", new_index);
    }
  }
}

</script>