import SupaBaseModel from "./SupaBaseModel";
import {SupabaseClient} from "./SuperbaseClient";

export default class NavigationList extends SupaBaseModel {
    static data_name = 'navigation_lists';


    get created_at_format(){
      return this.formatDate(this.created_at);
    }

    static async getByName(name){
        let { data, error }  = await this.client.select().match({ name: name });

        if(error) throw error;

        if(data.length > 0)
            return this.instanceFromData(data[0]);

        return null;
    }
}