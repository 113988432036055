
const Accordion = {
  mounted(el, binding, vnode) {
    //const vm = binding.instance

    console.log("... accordion", el);

    for(let label of el.getElementsByTagName('label')){
      console.log("label", label);
      label.addEventListener('click', event => {   console.log("click aa")
        for(let content of el.querySelectorAll('[data-accordion-content]')){

          content.style.maxHeight = `${content.scrollHeight}px`;
        }
      });
    }
  },
};

export default Accordion;
