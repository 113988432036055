export default (editor, config) => {
  const sm = editor.StyleManager;
  sm.clear();
  let sectors = sm.getSectors();
  console.log("...sectos ", sectors);
  console.log("..models", sm.getSectors().models);
  console.log("..config", sm.getConfig());

  sectors.reset();

  sectors.add([
     {
       label: 'Overlay',
       open: true,
       buildProps: [ 'top', 'left', 'bottom', 'right', 'z-index'],
       properties: [
         /*{
           type: 'radio',
           id: "overlay_display",
           property: 'display',
           label: 'Visibility',
           toRequire: true,
           options: [
             { id: 'block', label: 'Visible'},
             { id: 'none', label: 'Hidden'}
           ]
         },*/
         {
           property: 'top',
           label: 'Top',
           toRequire: true,
           units: ['%']
         },
         {
           property: 'left',
           label: 'Left',
           toRequire: true,
           units: ['%']
         },
         {
           property: 'bottom',
           label: 'Bottom',
           toRequire: true,
           units: ['%']
         },
         {
           property: 'right',
           label: 'Right',
           toRequire: true,
           units: ['%']
         },
         {
           type: 'number',
           property: 'z-index',
           label: 'level',
           toRequire: true,
           default: 1
         }
       ]
     },
     {
       name: 'Layout',
       open: true,
       buildProps: [  'display', 'max-width', 'height', 'flex-direction', 'justify-content',  'align-items', 'flex', 'flex-wrap', 'margin', 'padding'],
       properties: [
         {
           id: 'display',
           type: 'radio',
           property: 'display',
           label: 'Layout',
           toRequire: true,
            options: [
             { id: 'flex', label: 'Horizontal'},
             { id: 'block', label: 'Vertical'},
             { id: 'none', label: 'Hidden'}
           ]
         },
         {
           type: 'slider',
           property: 'max-width',
           label: 'Width',
           toRequire: true,
           units: ['%', 'px'],
           min: 0,
           default: '100%'
         },
         {
           type: 'slider',
           property: 'height',
           label: 'Height',
           toRequire: true,
           units: ['%', 'px'],
           min: 0,
           default: 'auto'
         },
         {
           type: 'select',
           property: 'flex-direction',
           label: 'Layout',
           toRequire: true,
           options: [
             {id: 'column', label: 'Vertical'},
             {id: 'row', label: 'Horizontal'},
             {id: 'column-reverse', label: 'Vertical (reverse)'},
             {id: 'row-reverse', label: 'Horizontal (reverse)'}
           ]
         },{
           type: 'select',
           property: 'justify-content',
           label: 'Horizntl Align',
           toRequire: true,
           options: [
             {id: 'flex-start', label: 'left'},
             {id: 'center', label: 'center'},
             {id: 'flex-end', label: 'right'},
             {id: 'space-between', label: 'space between'},
             {id: 'space-around', label: 'space around'},
             {id: 'space-evenly', label: 'space evenly'},
           ]
         },
         {
           type: 'select',
           property: 'align-items',
           label: 'Vertical Align',
           toRequire: true,
           default: "center",
           options: [
             {id: 'flex-start', label: 'top'},
             {id: 'center', label: 'center'},
             {id: 'flex-end', label: 'bottom'},
             {id: 'baseline', label: 'baseline'},
             {id: 'stretch', label: 'stretch'}
           ]
         },
         {
           type: 'select',
           property: 'flex',
           label: 'Fit',
           toRequire: true,
           options: [
             {id: '1 1 auto', label: 'shrink & stretch'},
             {id: '0 1 auto', label: 'stretch'},
             {id: '1 0 auto', label: 'shrink'},
             {id: '0 0 auto', label: 'fixed'},
           ]
         },
         {
           type: 'select',
           property: 'flex-wrap',
           label: 'Wrap',
           toRequire: true,
           default: "nowrap",
           options: [
             {id: 'nowrap', label: 'single line'},
             {id: 'wrap', label: 'multi line'}
           ]
         },
         {
           type: 'composite',
           property: 'margin',
           label: 'Margin',
           toRequire: true,
           default: '0px',
           properties:[
             { type: 'number', label: 'Top', property: 'margin-top', units: ['px', 'vw'], default: '0px'},
             { type: 'number', label: 'Right', property: 'margin-right', units: ['px', 'vw'], default: '0px'},
             { type: 'number', label: 'Left', property: 'margin-left', units: ['px', 'vw'], default: '0px'},
             { type: 'number', label: 'Bottom', property: 'margin-bottom', units: ['px', 'vw'], default: '0px'}
           ],
         },{
           property: 'padding',
           label: 'Padding',
           toRequire: true,
           properties:[
             { type: 'number', label: 'Top', property: 'padding-top', units: ['vw', 'px'], default: '0px'},
             { type: 'number',  label: 'Bottom', property: 'padding-bottom', units: ['vw', 'px'], default: '0px'},
             { type: 'number', label: 'Left', property: 'padding-left', units: ['vw', 'px'], default: '0px'},
             { type: 'number', label: 'Right', property: 'padding-right', units: ['vw', 'px'], default: '0px'}
           ]
         }
       ]
     },
     {
      name: 'Font',
      open: true,
      buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'line-height', 'text-align'],
      properties: [
        {
          property: 'font-family',
          type: 'radio',
          defaults: 'Poppins',
          toRequire: true,
          options: [
            { id: "'Poppins', sans-serif", label: 'Sans Serif'},
            { id: "'Cormorant Garamond', serif", label: 'Serif'},
            { id: "'Des Montilles'", label: 'Special'},
          ],
        },
        {
          property: 'font-size',
          type: 'number',
          defaults: '14',
          toRequire: true,
          units: ['px', 'vw']
        },
        {
          property: 'font-weight',
          toRequire: true,
        },
        {
          property: 'text-align',
          type: 'radio',
          defaults: 'left',
          toRequire: true,
          options: [
            { id: 'left', label: 'Left'},
            { id: 'center', label: 'Center'},
            { id: 'right', label: 'Right'},
            { id: 'justify', label: 'Justify'}
          ],
        },
        {
          property: 'letter-spacing',
          type: 'number',
          defaults: '0.05',
          toRequire: true,
          units: ['em']
        },
        {
          property: 'line-height',
          type: 'number',
          defaults: '1.4',
          toRequire: true,
          units: ['em']
        },
      ]
     },
    {
      name: 'Decoration',
      open: true,
      buildProps: ['color', 'background-color', 'border', 'border-radius', 'fill'],
      properties: [{
          type: 'color',
          property: 'fill',
          toRequire: true,
        }, {
          type: 'color',
          property: 'color',
          toRequire: true,
        }, {
          id: 'backgroundcolour',
          type: 'color',
          property: 'background-color',
          toRequire: true,
        },{
          property: 'border',
          toRequire: true,
        },{
          property: 'border-radius',
          toRequire: true,
        },
      ]
    }
    /*,{
     label: config.textTypography,
     open: false,
     buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-shadow'],
     properties: [{
       property: 'text-align',
       options: [
           { id: 'left', className: 'fa fa-align-left' },
           { id: 'center', className: 'fa fa-align-center'  },
           { id: 'right', className: 'fa fa-align-right' },
           { id: 'justify', className: 'fa fa-align-justify' },
       ],
     }]
   },{
     label: config.textDecorations,
     open: false,
     buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
   },{
     label: config.textExtra,
     open: false,
     buildProps: ['transition', 'perspective', 'transform'],
   }*/
   ]);
}
