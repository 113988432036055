import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage || {},
});

const store = createStore({
  plugins: [vuexLocal.plugin],
  state() {
    return {
      auth_session: null,
      show_mobile_menu: false,
    }
  },
  getters: {
    auth_session: (state) => state.auth_session,
    show_mobile_menu: (state) => state.show_mobile_menu
  },
  mutations: {
    setAuthSession(state, val) {
      state.auth_session = val;
    },
    setShowMobileMenu(state, val){
      state.show_mobile_menu = val;
    }
  },
  actions: {
    doSomething({commit, getters}){
      console.log("previous example_variable value", getters.example_variable )
      commit('setExampleVariable', 'new value');
    },
    toggleMobileMenu({commit, getters}){
      commit('setShowMobileMenu', !getters.show_mobile_menu);
    },
  }
});

export default store;
