<template>
  <footer class="footer">

      <a class="footer__logo" href="/" >
        <img class="footer__logo-svg" src="/logo.svg" alt="Just Cuts">
      </a>

      <div class="footer__nav">
          <div class="footer__nav-column" v-for="nav_link in nav.list">
            <a class="footer__nav-heading" :href="nav_link.path" >{{ nav_link.title }}</a>

            <div class="footer__nav-links">
              <a class="footer__nav-link" v-for="child_link in nav_link.children" :href="child_link.path">
                {{ child_link.title }}
              </a>
            </div>
          </div>
      </div>

      <div class="footer__copyright">
        © Copyright 2023. All Rights Reserved Just Cuts
      </div>

  </footer>
</template>


<script>

import NavigationList from "../../models/NavigationList";

export default {

  data(){
    return{
      nav: [],
    }
  },
  async mounted(){
    this.nav = await NavigationList.getByName("footer");
  }
}
</script>
