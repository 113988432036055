<template>
  <div>
    <public-mobile-menu v-if="!is_admin_route"></public-mobile-menu>
    <public-header v-if="!is_admin_route"></public-header>

    <router-view></router-view>
    <public-footer v-if="!is_admin_route"></public-footer>
  </div>
</template>


<script>

import { SupabaseClient } from './models/SuperbaseClient'

export default {
  data(){
    return{

    }
  },
  mounted(){

    SupabaseClient.auth.getSession().then(({ data }) => {
      this.$store.commit('setAuthSession', data.session);
     // console.log("auth_session", this.auth_session)
    });

    SupabaseClient.auth.onAuthStateChange((_, _session) => {
      this.$store.commit('setAuthSession', _session);
    });
  },
  computed:{
    is_admin_route(){
      return this.$route.path.includes("/admin")
    },
    auth_session(){
      return this.$store.getters.auth_session;
    },
    user_email(){
      return this.$store.getters.auth_session && this.$store.getters.auth_session.user ? this.$store.getters.auth_session.user.email : null;
    }
  },
  methods:{

  }
}
</script>
