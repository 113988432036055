<template>
  <section class="opportunities__page-group">
    <a class="opportunities__item" v-for="opportunity in opportunities" :href="`/franchising-opportunities/franchise-details/${opportunity.id}`">
      <div class="opportunities__item-image">
        <div class="opportunities__item-image-inner">
          <img class="opportunities__item-img" :src="opportunity.main_image_url"  alt="Salon Image" />
        </div>
      </div>
      <div class="opportunities__item-details">
        <h2 class="opportunities__item-title">{{ opportunity.title }}</h2>
        <h3  class="opportunities__item-location">{{ opportunity.suburb }} {{ opportunity.state_abbreviation }}, {{ opportunity.country_name }}</h3>
      </div>
    </a>
  </section>
</template>


<script>

import FranchiseOpportunity from "../../models/FranchiseOpportunity";

export default {

  data(){
    return{
      opportunities: [],
    }
  },
  async mounted(){
    this.opportunities = await FranchiseOpportunity.allActiveWithDetails(6);
  }
}
</script>
