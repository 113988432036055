import SupaBaseModel from "./SupaBaseModel";
import {SupabaseClient} from "./SuperbaseClient";

export default class ContentPageEdit extends SupaBaseModel {
    static data_name = 'content_page_edits';

    static newDefault(){
        let instance = new this();
        instance.html = "";
        instance.css = "";
        instance.components = [];
        instance.styles = [];
        return instance;
    }

    copy(){
        let instance = new ContentPageEdit();
        instance.html = this.html;
        instance.css = this.css;
        instance.components = this.components ;
        instance.styles = this.styles;
        instance.content_page_id = this.content_page_id;
        return instance;
    }

    get created_at_format(){
      return this.formatDate(this.created_at);
    }

    static async getRecent10ForContentPage(content_page){
        let { data, error }  = await this.client.select()
                                .eq("content_page_id", content_page.id)
                                .order("created_at", { ascending: false })
                                .limit(10);

        if(error) throw error;

        return this.instanceItemsFromData(data);
    }
}