<template>
    <div class="mobile-menu" :class="{ 'mobile-menu--visible': show_mobile_menu }">
        <div class="mobile-menu__nav-container">
            <div class="mobile-menu__nav-panel">
                <div class="mobile-menu__nav" v-for="(nav_link, index) in nav.list" :key="index">
                    <router-link class="mobile-menu__nav-link mobile-menu__nav-link--parent"  :to="nav_link.path"  @click.native="closeMenu" >{{ nav_link.title }}</router-link>
                    <div class="mobile-menu__sub-nav" v-if="nav_link.children.length > 0 ">
                        <router-link class="mobile-menu__nav-link mobile-menu__nav-link--child"
                                     v-for="(child_link, child_index) in nav_link.children"
                                     :key="`${index}_${child_index}`"
                                     :to="child_link.path"
                                      @click.native="closeMenu">
                          {{ child_link.title }}
                        </router-link>
                    </div>
                </div>
            </div>
          <!--
            <div class="mobile-menu__nav-panel">
                <div class="mobile-menu__nav">
                    <a class="mobile-menu__nav-link mobile-menu__nav-link--parent" href="tel:+61 2 8052 3795">Call Us</a>
                </div>

                <div class="mobile-menu__nav" v-for="nav_item in nav" :key="nav_item.path">
                    <router-link class="mobile-menu__nav-link mobile-menu__nav-link--parent"  :to="nav_item.path"  @click.native="closeMenu" >{{ nav_item.title }}</router-link>
                    <div class="mobile-menu__sub-nav">
                        <router-link class="mobile-menu__nav-link mobile-menu__nav-link--child"  v-for="subnav_item in nav_item.children" :key="subnav_item.path" :to="subnav_item.path"  @click.native="closeMenu" >{{ subnav_item.title }}</router-link>
                    </div>
                </div>
            </div>
            -->
        </div>
        <div class="mobile-menu__close-area" @click="closeMenu"></div>
    </div>
</template>

<script>
  import NavigationList from "../../models/NavigationList";

  export default{
    data(){
      return{
        nav: []
      }
    },
    async mounted(){
      let navs = await NavigationList.all();
      this.nav = navs.find( u => u.name == "mobile menu");
    },
    computed:{
      show_mobile_menu(){
        return this.$store.getters.show_mobile_menu;
      }
    },
    methods:{
      closeMenu(event){
        this.$store.commit('setShowMobileMenu', false);
      }
    }
  }
</script>
