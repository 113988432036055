import {
    cmdClear,
    cmdDeviceDesktop,
    cmdDeviceMobile,
    cmdDeviceTablet
} from "./constants"


export default (editor, config) => {
    const { Commands } = editor
    const txtConfirm = config.textCleanCanvas

   // openImport(editor, config)

    Commands.add(cmdDeviceDesktop, {
        run: ed => ed.setDevice("desktop"),
        stop: () => {}
    })
    Commands.add(cmdDeviceTablet, {
        run: ed => ed.setDevice("tablet"),
        stop: () => {}
    })
    Commands.add(cmdDeviceMobile, {
        run: ed => ed.setDevice("mobile"),
        stop: () => {}
    })
    Commands.add(
        cmdClear,
        e => confirm(txtConfirm) && e.runCommand("core:canvas-clear")
    )
}
