<template>
    <div class="image-slider">
        <div class="image-slider__slides" ref="scroll_container" >
            <div class="image-slider__slide" v-for="(image, index) in images" :key="index" :data-index="index">
               <img class="image-slider__slide-img" :alt="alt" :src="image" v-if="index < display_count" />
            </div>
        </div>
        <div class="image-slider__action image-slider__action--prev" v-if="show_prev_action" @click="prev__clicked"></div>
        <div class="image-slider__action image-slider__action--next" v-if="show_next_action" @click="next__clicked"></div>
        <div class="scroll-pagination image-slider__pagination" v-if="show_pagination">
            <div class="scroll-pagination__dot"
                 v-for="(image, index) in images"
                 :key="index"
                 :class="{ 'scroll-pagination__dot--active': index === current_index}">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
      images: Array,
      initial_display_count:{
        type: Number,
        default: 2
      },
      alt: {
        type: String,
        default: "Just Cuts"
      },
      observe_full_viewport:{
        type: Boolean,
        default: false
      },
      show_pagination:{
        type: Boolean,
        default: true
      },
      show_next_action:{
        type: Boolean,
        default: true
      },
      show_prev_action:{
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
        current_index: 0,
        display_count: 2,
        observer: null
      }
    },
    mounted(){
        this.display_count = this.initial_display_count;
        this.createObserver();
        this.observeChildren();

       // console.log("this.display_count", this.display_count);

      setTimeout(() => {
        this.$refs.scroll_container.scroll({ left: 0 });
        this.current_index = 0;
      }, 800);
    },
    methods:{
      createObserver(){
        this.observer = new IntersectionObserver(this.handleIntersect, {
          root: (this.observe_full_viewport ? null : this.$refs.scroll_container),
          rootMargin: "0px",
          threshold: 0.9
        });
      },
      observeChildren(){
        this.$refs.scroll_container.scroll({ left: 0 });
        this.current_index = 0;
       // console.log("image changed");
        this.$nextTick( () => {
          for(let child of this.$refs.scroll_container.children){
           // console.log("scroll children");
            this.observer.observe(child);
          }
        });
      },
      handleIntersect(entries, observer){
        entries.forEach(entry => {
          let index = parseInt(entry.target.dataset.index);
         // console.log("intersect", index, entry.isIntersecting, entry.intersectionRect.width, entry);
          if(entry.isIntersecting && entry.intersectionRect.width > 0) {
            this.current_index = parseInt(entry.target.dataset.index);
          } else if(index === 0 && this.display_count < 100) {
          //  console.log("load slider images");
            this.display_count = 100;
          }
        });
      },
      next__clicked(event){
        let index = this.current_index + 1;
        let count = this.$refs.scroll_container.children.length;
        this.scrollToIndex(index < count ? index : 0);
      },
      prev__clicked(event){
        let index = this.current_index - 1;
        let count = this.$refs.scroll_container.children.length;
        this.scrollToIndex(index >= 0 ? index : count - 1);
      },
      scrollToIndex(index){
       // console.log("index", index, this.current_index);
        let rect =  this.$refs.scroll_container.children.item(index).getBoundingClientRect();
        let scrollRect = this.$refs.scroll_container.getBoundingClientRect();
        this.$refs.scroll_container.scroll({ left: (rect.left - scrollRect.left) + this.$refs.scroll_container.scrollLeft, behavior: 'smooth' });
        //this.$refs.scroll_container.scroll({left: this.$refs.scroll_container.scrollLeft + rect.left, behavior: 'smooth'});

      }
    },
    watch:{
      images(value){
       // console.log("images watch");
        this.observeChildren();
      }
    }
}
</script>
