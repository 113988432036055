import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator }  from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyBfGmHFsI6XODuaAAoDJzro4MygMxKgROs",
  authDomain: "justcuts-web.firebaseapp.com",
  projectId: "justcuts-web",
  storageBucket: "justcuts-web.appspot.com",
  messagingSenderId: "199554263122",
  appId: "1:199554263122:web:d9ac352e8d0a7b04e7d78b",
  measurementId: "G-RMF5NLVFL1"
};

const firebaseApp = initializeApp(firebaseConfig);

// utils
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, "australia-southeast1");

//connectFunctionsEmulator(functions, 'localhost', '5001'); // For Testing

export {
  db,
  auth,
  functions
}


