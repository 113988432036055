<template>
  <header class="header">

    <div class="header__menu-toggle hide--larger-than-mobile">
      <label class="menu-toggle__icon" :class="{ 'menu-toggle__icon--selected': show_mobile_menu }" @click="mobileMenu__toggled">
        <span class="menu-toggle__line menu-toggle__line--top"></span>
        <span class="menu-toggle__line menu-toggle__line--middle"></span>
        <span class="menu-toggle__line menu-toggle__line--bottom"></span>
      </label>
    </div>


    <a class="header__logo" href="/">
      <img class="header__logo-svg" src="/logo.svg" alt="Just Cuts Logo" />
    </a>

    <nav class="header__nav hide--mobile">
      <div class="header__nav-item" v-for="nav_link in nav.list">

        <div class="header__nav-dropdown" v-if="nav_link.children.length > 0 ">
          <a class="header__nav-childlink" v-for="child_link in nav_link.children" :href="child_link.path">{{ child_link.title }}</a>
        </div>

        <a class="header__nav-link" :href="nav_link.path">{{ nav_link.title }}</a>



      </div>
    </nav>


    <div class="header__right-panel">
      <router-link class="button button--fit button--round button--size-m header__enquire-button"  to="/franchise-enquiry-form">Enquire</router-link>
      <router-link class="header__call-link hide--mobile"  to="/book-a-call">
        <svg viewBox="0 0 28 28">
          <path d="M.77,20.19l.71,1.58a2,2,0,0,0,2.7,1,17,17,0,0,0,8-21A2,2,0,0,0,9.52.68l-1.58.71A2,2,0,0,0,6.85,3.82l.76,2.42A2,2,0,0,0,8.8,7.5l2.45.93a15.12,15.12,0,0,1-3.32,8.71L5.5,16.21a2,2,0,0,0-1.73.15l-2.2,1.31A2,2,0,0,0,.77,20.19Z" transform="translate(7.72 2.81)"></path>
        </svg>
      </router-link>
    </div>
  </header>
</template>


<script>

import NavigationList from "../../models/NavigationList";

export default {

  data(){
    return{
      nav: []
    }
  },
  async mounted(){
    let navs = await NavigationList.all();
    this.nav = navs.find( u => u.name == "header");
  },
  computed: {
    show_mobile_menu() {
      return this.$store.getters.show_mobile_menu;
    }
  },
  methods:{
    mobileMenu__toggled(event){
      this.$store.dispatch('toggleMobileMenu');
    }
  }
}
</script>
